import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import ReplyIcon from '@mui/icons-material/Reply';
import {Divider} from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import {observer} from 'mobx-react-lite';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Linkify from 'react-linkify';
import VisibilitySensor from 'react-visibility-sensor';

import {GuestbookFileCardComponent} from '../../components/guestbook/guestbook-file-card.component';
import {GuestbookFilesLightboxComponent} from '../../components/guestbook/guestbook-file-lightbox.component';
import {GuestbookLikesComponent} from '../../components/guestbook/guestbook-likes.component';
import {GuestbookPostDeleteDialogComponent} from '../../components/guestbook/guestbook-post-delete-dialog.component';
import {GuestbookPostInputComponent} from '../../components/guestbook/guestbook-post-input.component';
import {GuestbookPostMessageComponent} from '../../components/guestbook/guestbook-post-message.component';
import {GuestbookPosViewsComponent} from '../../components/guestbook/guestbook-post-views.component';
import {GuestbookReplyInputComponent} from '../../components/guestbook/guestbook-reply-input.component';
import {usePatientRole} from '../../hooks/patients/use-patient-role';
import {useStores} from '../../hooks/use-stores';
import {GuestbookPost} from '../../interfaces/entities/guestbook-post.interface';
import {GuestbookReply} from '../../interfaces/entities/guestbook-reply.interface';
import {Patient} from '../../interfaces/entities/patient.interface';
import {BLACK_COLOR, LIGHT_GRAY_COLOR} from '../../theme';
import {activeVisibilitySensorForGuestbookPost} from '../../utils/active-visibility-sensor-for-guestbook-post';
import {isVideoUrl} from '../../utils/is-video-url';
import {GuestbookReplyCardContainer} from './guestbook-reply-card.container';

export interface GuestbookPostCardProps extends Omit<GuestbookPost, 'guestbookFiles'> {
  patientId: Patient['id'];
  guestbookFiles?: GuestbookPost['guestbookFiles'];
  isLoading?: boolean;
}

function GuestbookPostCard({
  id,
  user,
  message,
  createdAt,
  guestbookLikes,
  guestbookFiles,
  replies,
  patientId,
  parent,
  isNew: isNewDefault,
  readByMe,
  isLoading,
  readBy: defaultReadBy,
  commonId,
}: GuestbookPostCardProps) {
  const {t} = useTranslation();

  const {guestbookPostsStore: store, patientsStore, authStore} = useStores();
  const {role} = usePatientRole(patientId);

  const [isNew, setIsNew] = useState(isNewDefault);
  const [readBy, setReadBy] = useState(defaultReadBy);

  useEffect(() => {
    if (!isNewDefault && isNew) {
      setIsNew(false);
      setReadBy(defaultReadBy);
    }
  }, []);

  const [showEdit, setShowEdit] = useState<boolean>(false);

  const [indexFileToShow, setIndexFileToShow] = useState<number | null>(null);

  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);

  const [showNewReply, setShowNewReply] = useState<boolean>(false);

  const [countLikes, setCountLikes] = useState(guestbookLikes.length);
  const [isLiked, setIsLiked] = useState(
    (authStore.me?.id && store.getLikes(patientId)?.[id]?.[authStore.me.id]) || false
  );

  const likeId = useMemo(() => {
    const likes = store.getLikes(patientId);

    if (!authStore.me?.id || !likes[id]) {
      return;
    }
    return likes[id][authStore.me.id];
  }, [id, authStore.me?.id, store.getLikes(patientId)]);

  const showEditDelete = useMemo(() => {
    if (!authStore.me?.id || !user?.id) {
      return;
    }
    return authStore.me.id === user.id;
  }, [authStore.me?.id, user?.id, patientId]);

  const showCountLikes = useMemo(() => {
    if (!countLikes) {
      return false;
    }

    if (countLikes === 1 && isLiked) {
      return false;
    }

    return true;
  }, [countLikes, isLiked]);

  const activeToRead = useMemo(() => {
    return !!(
      authStore.me?.id &&
      activeVisibilitySensorForGuestbookPost(authStore.me.id, {
        readByMe,
        user,
      })
    );
  }, [authStore.me?.id, readByMe, user]);

  const onClickLike = useCallback(() => {
    if (isLiked) {
      setIsLiked(false);
      setCountLikes(countLikes - 1);
    } else {
      setIsLiked(true);
      setCountLikes(countLikes + 1);
    }
    if (likeId) {
      store.removeLike(patientId, id, likeId);
    } else {
      store.addLike(patientId, id);
    }
  }, [id, isLiked, likeId, patientId]);

  const onClickFile = useCallback(
    (index: number | string) => {
      setIndexFileToShow(index as number);
    },
    [setIndexFileToShow]
  );

  const onCloseLightBox = useCallback(() => {
    setIndexFileToShow(null);
  }, [setIndexFileToShow]);

  const onClickEdit = useCallback(() => {
    setShowEdit(true);
  }, [setShowEdit]);

  const onCancelEdit = useCallback(() => {
    setShowEdit(false);
  }, [setShowEdit]);

  const onSubmitEdit = useCallback(
    async (data: Partial<GuestbookPost>) => {
      store.updateByPatientId(patientId, id, data as GuestbookPost);
      onCancelEdit();
    },
    [patientId, id, onCancelEdit, store.updateByPatientId]
  );

  const onClickDelete = useCallback(() => {
    setShowDeleteDialog(true);
  }, [setShowDeleteDialog]);

  const onCloseDelete = useCallback(() => {
    setShowDeleteDialog(false);
  }, [setShowDeleteDialog]);

  const onSubmitDelete = useCallback(async () => {
    onCloseDelete();
    store.deleteByPatientId(patientId, id);
  }, [patientId, id, onCloseDelete, store.deleteByPatientId]);

  const onClickReply = useCallback(() => {
    setShowNewReply(!showNewReply);
  }, [setShowNewReply, showNewReply]);

  const onCancelReply = useCallback(() => {
    setShowNewReply(false);
  }, [setShowNewReply]);

  const onSubmitReplyInput = useCallback(
    (data: Partial<GuestbookReply>) => {
      store.createReplyByPatientId(patientId, id, {
        ...data,
        guestbookLikes: [],
      } as GuestbookReply);
      setShowNewReply(false);
    },
    [patientId, id, setShowNewReply]
  );

  const onRead = useCallback(
    (visible: boolean) => {
      if (!visible || !activeToRead) {
        return;
      }

      if (role) {
        patientsStore.readGuestbookPost(patientId, id, commonId, authStore.me, role);
      }
    },
    [activeToRead, patientId, id, role, commonId]
  );

  return (
    <Box
      position={'relative'}
      p={3}
      mb={3}
      sx={{
        borderRadius: '5px',
        boxShadow: `0 0 20px ${LIGHT_GRAY_COLOR}`,
        ...(isNew
          ? {
              backgroundColor: 'primary.light',
              borderColor: 'primary.main',
              borderStyle: 'solid',
            }
          : {}),
      }}
    >
      <Box
        sx={
          showEdit
            ? {
                border: '1px solid',
                borderRadius: '3px',
                borderColor: 'primary.main',
                backgroundColor: 'primary.light',
                padding: 2,
              }
            : {}
        }
      >
        {!!(indexFileToShow !== null && guestbookFiles) && (
          <GuestbookFilesLightboxComponent
            files={guestbookFiles}
            defaultIndex={indexFileToShow}
            onClose={onCloseLightBox}
          />
        )}
        <GuestbookPostDeleteDialogComponent
          open={showDeleteDialog}
          onCancel={onCloseDelete}
          onConfirm={onSubmitDelete}
        />
        <VisibilitySensor
          active={activeToRead}
          delayedCall
          onChange={onRead}
          partialVisibility={'bottom' as unknown as boolean}
        >
          <Grid container direction="column" justifyContent="flex-start" alignItems="stretch" wrap="nowrap" spacing={2}>
            {user && (
              <Grid item>
                <GuestbookPostMessageComponent
                  {...user}
                  createdAt={createdAt}
                  showMenuButton={!showEdit && showEditDelete}
                  onClickDelete={onClickDelete}
                  onClickEdit={onClickEdit}
                  isNew={isNew}
                  commonId={commonId}
                />
              </Grid>
            )}
            {!showEdit ? (
              <>
                <Grid item>
                  <Typography variant={'body1'} whiteSpace={'pre-line'} sx={{wordBreak: 'break-word'}}>
                    <Linkify
                      componentDecorator={(decoratedHref, decoratedText, key) => (
                        <a target="_blank" href={decoratedHref} key={key} style={{color: BLACK_COLOR}} rel="noreferrer">
                          {decoratedText}
                        </a>
                      )}
                    >
                      {message}
                    </Linkify>
                  </Typography>
                </Grid>
                {!!guestbookFiles?.length && (
                  <Grid item>
                    <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={2}>
                      {guestbookFiles.map((file, index) => (
                        <Grid
                          item
                          key={index}
                          sx={theme => ({
                            width: '50%',
                            [theme.breakpoints.down('sm')]: {
                              width: isVideoUrl(file.url) ? '100%' : '50%',
                            },
                          })}
                        >
                          <Box>
                            <GuestbookFileCardComponent
                              key={file.id || index}
                              {...file}
                              id={index}
                              onClick={onClickFile}
                            />
                          </Box>
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                )}
                <Grid item>
                  <Stack direction="row" spacing={1} justifyContent="space-between">
                    <Stack
                      direction="row"
                      justifyContent={'flex-start'}
                      spacing={{
                        xs: 3,
                        sm: 4,
                        md: 5,
                        lg: 6,
                      }}
                    >
                      <Stack direction="row" justifyContent="center" alignItems="center">
                        <IconButton
                          onClick={onClickLike}
                          sx={{
                            color: 'secondary.dark',
                            paddingLeft: 0,
                          }}
                        >
                          {isLiked ? <FavoriteIcon fontSize={'large'} /> : <FavoriteBorderIcon fontSize={'large'} />}
                        </IconButton>
                        {showCountLikes && <GuestbookLikesComponent items={guestbookLikes} />}
                      </Stack>
                      {!parent && (
                        <Button
                          variant="text"
                          startIcon={<ReplyIcon />}
                          sx={{
                            color: 'secondary.dark',
                          }}
                          onClick={onClickReply}
                        >
                          {t('guestbook-reply.button')}
                        </Button>
                      )}
                    </Stack>
                    {!parent && readBy && readBy.length > 0 && (
                      <GuestbookPosViewsComponent items={readBy} />
                    )}
                  </Stack>
                </Grid>
              </>
            ) : (
              <Grid item>
                <GuestbookPostInputComponent
                  id={id}
                  message={message}
                  guestbookFiles={guestbookFiles}
                  onCancel={onCancelEdit}
                  onSubmit={onSubmitEdit}
                />
              </Grid>
            )}

            <Grid item>
              <Stack
                direction="column"
                spacing={1}
                sx={theme => ({
                  [theme.breakpoints.up('md')]: {
                    padding: '0 32px',
                  },
                })}
              >
                {replies.map(reply => (
                  <Box key={reply.id} position={'relative'} mb={2}>
                    <Divider sx={{marginBottom: '15px'}} />
                    <GuestbookReplyCardContainer
                      patientId={patientId}
                      {...(!store.updatingReplies[id]?.[reply.id] ? reply : store.updatingReplies[id]?.[reply.id])}
                      isNew={reply.isNew}
                    />
                    {(store.updatingReplies[id]?.[reply.id] || store.deletingReplies[id]?.includes(reply.id)) && (
                      <Backdrop
                        sx={{
                          position: 'absolute',
                          backgroundColor: 'rgba(255, 255, 255, 0.5)',
                          zIndex: theme => theme.zIndex.drawer + 1,
                        }}
                        open={true}
                      >
                        <CircularProgress color="primary" />
                      </Backdrop>
                    )}
                  </Box>
                ))}
                {(store.creatingReplies[id] || []).map(reply => (
                  <Box key={reply.id} position={'relative'}>
                    <GuestbookReplyCardContainer patientId={patientId} {...reply} />
                    <Divider />
                    <Backdrop
                      sx={{
                        position: 'absolute',
                        backgroundColor: 'rgba(255, 255, 255, 0.5)',
                        zIndex: theme => theme.zIndex.drawer + 1,
                      }}
                      open={true}
                    >
                      <CircularProgress color="primary" />
                    </Backdrop>
                  </Box>
                ))}
                {showNewReply && (
                  <Box display="block" sx={{width: '100%'}}>
                    <GuestbookReplyInputComponent
                      onCancel={onCancelReply}
                      onSubmit={onSubmitReplyInput}
                      parentId={id}
                    />
                  </Box>
                )}
              </Stack>
            </Grid>
          </Grid>
        </VisibilitySensor>
      </Box>
      {isLoading && (
        <Backdrop
          sx={{
            position: 'absolute',
            backgroundColor: 'rgba(255, 255, 255, 0.5)',
            zIndex: theme => theme.zIndex.drawer + 1,
          }}
          open={true}
        >
          <CircularProgress color="primary" />
        </Backdrop>
      )}
    </Box>
  );
}
export const GuestbookPostCardContainer = observer(GuestbookPostCard);
